<template>
  <div class="cook">
    <div class="bg"></div>
    <div class="cook__icons">
      <img src="@/assets/img/icons/star.svg" alt="" class="icon star1">
      <img src="@/assets/img/icons/yellow-star.svg" alt="" class="icon star2">
      <img src="@/assets/img/icons/yellow-star.svg" alt="" class="icon star3">
      <img src="@/assets/img/icons/yellow-star.svg" alt="" class="icon star4">
      <img src="@/assets/img/icons/star.svg" alt="" class="icon star5">

      <img src="@/assets/img/icons/smiles/cool.png" alt="" class="icon smile1">
      <img src="@/assets/img/icons/smiles/smile-love.png" alt="" class="icon smile2 forMobile_or_p">
    </div>
    <div class="cook__label">
      «Рестория»&nbsp;&mdash; <span>твой главный помощник на&nbsp;кухне!</span>
    </div>

    <p>
      Разогреть готовые или приготовить любимые блюда легко и&nbsp;быстро с&nbsp;продуктами &laquo;Рестория&raquo;!<br /><br />
      <span>Эксклюзивно в&nbsp;&laquo;Пятерочке&raquo;.</span>
    </p>
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss">
.cook {
  position: relative;

  width: 77em;
  margin: 1.7em auto 0;
  padding-top: 11em;
  padding-right: 2em;
  padding-bottom: 12em;

  font-size: 1rem;

  background: url("@/assets/img/cook/bg.png") no-repeat top 1em center / contain;

  &__label {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0.4em;
    padding: 0;

    font-size: em(104px);
    text-align: center;
    font-weight: 700;
    text-align: center;
    line-height: 0.9;
    color: $red;

    background: none;
  }

  p {
    width: 19.4em;
    margin: 0 auto;

    font-family: Circe, Arial;
    font-size: em(32px);
    line-height: 1.25;
    font-weight: 700;
    text-align: center;

    span {
      color: $red;
    }
  }

  .icon {
    position: absolute;
    z-index: 1;
  }

  .star1 {
    top: 21.7em;
    left: -18.7em;
    width: 9em;
  }
  .star2 {
    top: 18.7em;
    left: -1.4em;
    width: 6em;
    z-index: -1;
  }
  .star3 {
    top: 13.7em;
    right: -12.2em;
    width: 9em;
  }
  .star4 {
    top: 31.5em;
    right: -18.9em;
    width: 2.1em;
  }
  .star5 {
    top: 31.5em;
    right: 0.5em;
    width: 4.3em;
  }

  .smile1 {
    bottom: 1.9em;
    left: 5em;
    width: 11em;
  }
  .smile2 {
    top: 31.1em;
    left: -2.9em;
    width: 6em;
    transform: rotate(-73deg);
  }
  .smile3 {
    top: 23.1em;
    right: 3.1em;
    width: 9.3em;
  }
}

@include mobile_or_P {
  .cook {
    position: relative;

    width: 100%;
    padding: 4em 0 5.5em;

    background: none;

    .bg {
      position: absolute;
      top: -5.3%;
      left: 57%;
      z-index: -1;
      width: 29em;
      height: 36em;
      transform: translateX(-50%);
      background: url("@/assets/img/cook/bg-m.png") no-repeat center center / auto 100%;
    }

    p {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      width: 14em;
      margin: 1.4em auto;

      font-family: Circe;
      font-size: em(16px);
      font-weight: 700;
      line-height: 1.35;

      img {
        margin: -0.6em -0.4em;
      }

      span {
        display: block;
        text-align: center;
        color: $red;

        width: 100%;
      }

      b {
        font-weight: 400;
      }
    }

    .smile1 {
      top: 0;
      bottom: auto;
      width: 4em;
      z-index: 5;
      left: 4em;
    }

    .smile2 {
      top: auto;
      left: auto;
      right: 2em;
      bottom: -1em;
      transform: rotate(10deg);
    }

    .star2 {
      display: block;
      top: 30.3em;
      left: 2.9em;
      width: 2em;
    }

    /*.smile2 {
      top: -0.6em;
      left: 0.9em;
      width: 4.2em;
      display: block;
      transform: rotate(-50deg);
    }*/
    .smile3 {
      top: auto;
      bottom: 0;
      right: 1.7em;
      width: 5.7em;
      display: block;
    }

    &__label {
      position: relative;
      z-index: 1;

      width: 9em;
      margin: -0.2em auto 0em;
      padding: 0.5em 1em;

      font-size: em(38px);
      font-weight: 700;
      line-height: 0.9;
      letter-spacing: 0.02em;

      img {
        position: absolute;
        bottom: 0.3em;
        right: 2.5em;
        width: 0.6em;
      }

      .star {
        position: absolute;
        top: 2.7em;
        left: 5em;

        width: 0.6em;
      }

      .star2 {
        top: auto;
        right: 4.2em;
        bottom: -0.5em;
        left: auto;

        width: 0.5em;
      }

      .smile {
        right: -0.2em;
        top: -0.7em;

        width: 2em;
      }
      /*.smile2 {
        right: auto;
        bottom: -4.3em;
        left: -1.7em;
        width: 3.4em;
      }*/
      .smile3 {
        right: -1.3em;
        bottom: -2em;
        top: auto;
        width: 3.4em;
      }
    }
  }
}

</style>
