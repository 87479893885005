import { computed, ref, onMounted } from 'vue';

export default function useWindowSize() {
  const random = ref(0);

  const isMobile = computed(() => {
    console.log(random.value);
    return window.innerWidth < 900;
  });

  onMounted(() => {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        random.value = Math.random();
      });
    });
  });

  return isMobile;
}
