import { createApp } from 'vue';
import VueSmoothScroll from 'v-smooth-scroll';
import App from './App.vue';

import './js/disableScroll';

import './assets/scss/main.scss';
import 'swiper/components/pagination/pagination.min.css';

// eslint-disable-next-line import/order
import SwiperCore, {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);
const app = createApp(App);
app.use(VueSmoothScroll);
app.mount('#app');
