import { onMounted, ref } from 'vue';

export default function useWindowLoad(callback?: any, delay?: number) {
  const isLoad = ref(false);

  onMounted(() => {
    window.addEventListener('load', () => {
      setTimeout(() => {
        isLoad.value = true;

        if (callback) {
          callback();
        }
      }, delay || 1000);
    });
  });

  return isLoad;
}
