export default [
  {
    id: 2,
    name: 'Говяжьи тефтели в&nbsp;томатном соусе',
    picture: '2.jpg',
    ingredients: [
      '400 г говяжьего фарша «Рестория»',
      '1 луковица',
      '4–5 ст. л. томатной пасты «Рестория»',
      '1 яйцо',
      '2–3 ст. л. панировочных сухарей',
      'Укроп, петрушка',
      'Соль и специи по вкусу',
      'Растительное масло',
    ],
    process: 'Мелко измельчи лук, добавь в&nbsp;фарш вместе со&nbsp;специями, панировочными сухарями и&nbsp;яйцом. Томатную пасту смешай с&nbsp;водой в&nbsp;пропорции 1:2, посоли и&nbsp;поперчи. Придай форму тефтелей и&nbsp;обжарь в&nbsp;масле до&nbsp;румяной корочки. Затем влей томатную смесь, туши на&nbsp;медленном огне 15&nbsp;минут. В&nbsp;конце добавь свежую зелень.',
    sku: 'Говяжий Фарш',
    time: 45,
  },
  {
    id: 3,
    name: 'Запеченные купаты с&nbsp;картофелем',
    picture: '3.jpg',
    ingredients: [
      '800 г фирменных купат «Рестория»',
      '5 картофелин',
      '2 ст. л. томатного кетчупа «Рестория»',
      '2–3 зубчика чеснока',
      '50 мл растительного масла',
      '1 ст. л. соевого соуса',
      'Соль',
      'Смесь перцев',
      'Приправа для картофеля',
    ],
    process: 'Нарежь картофель дольками, посоли, поперчи, добавь масло и&nbsp;перемешай. Промажь купаты с&nbsp;двух сторон смесью из&nbsp;кетчупа, соевого соуса и&nbsp;мелко нарезанного чеснока. Противень со&nbsp;всеми ингредиентами отправь в&nbsp;разогретую до&nbsp;200 градусов духовку на&nbsp;40&nbsp;минут.',
    sku: 'Фирменные Купаты',
    time: 60,
  },
  {
    id: 4,
    name: 'Кордон блю с&nbsp;сыром и&nbsp;ветчиной',
    picture: '4.jpg',
    ingredients: [
      'Кордон блю с сыром и ветчиной «Рестория»',
      '',
      'Майонез с перепелиными яйцами «Рестория»',
      '',
      '3–4 ст. л. растительного масла',
      '',
      'Зелень для украшения',
    ],
    process: 'Разогрей масло на&nbsp;сковороде. Выложи на&nbsp;нее кордон блю без разморозки. На&nbsp;среднем огне обжарь по&nbsp;5&ndash;7 минут с&nbsp;каждой стороны. Подавать можно с&nbsp;зеленью и&nbsp;майонезом.',
    sku: 'Кордон Блю с сыром и ветчиной',
    time: 15,
  },
  {
    id: 5,
    name: 'Индейка с&nbsp;грибами в&nbsp;сырном соусе',
    picture: '5.jpg',
    ingredients: [
      '500 г азу из индейки «Рестория»',
      '1 луковица',
      '1 морковь',
      '150 мл сливок 22%',
      '250 г шампиньонов',
      '100 г сыра',
      'Черный молотый перец «Рестория»',
      'Сушеный базилик',
      'Соль',
      'Растительное масло',
    ],
    process: 'На&nbsp;разогретую с&nbsp;растительным маслом сковородку отправь сухие кусочки индейки и&nbsp;шампиньонов. Жарь на&nbsp;сильном огне 10&nbsp;минут. Теперь добавь мелко нарезанные морковь и&nbsp;лук, уменьшив огонь. Жарь 5&ndash;7&nbsp;минут, помешивая, добавь соль и&nbsp;специи. Далее влей сливки вместе с&nbsp;тертым сыром, тщательно перемешай, накрой крышкой и&nbsp;туши на&nbsp;маленьком огне 10&nbsp;минут.',
    sku: 'Азу из Индейки',
    time: 50,
  },
  {
    id: 6,
    name: 'Хачапури из&nbsp;слоеного теста',
    picture: '6.jpg',
    ingredients: [
      '500 г слоеного бездрожжевого теста «Рестория»',
      '350 г твердого сыра',
      '1 яйцо',
      'Растительное масло',
    ],
    process: 'Раскатай тесто в&nbsp;пласт толщиной 3&nbsp;мм и&nbsp;порежь на&nbsp;квадраты. Твердый сыр натри на&nbsp;крупной терке, добавь сырое яйцо и&nbsp;перемешай. В&nbsp;центр каждого квадрата выложи 1&nbsp;ст.&nbsp;л. начинки. Скрепи края теста, формируя конвертик. Выложи изделия на&nbsp;противень с&nbsp;пергаментом, смазанным растительным маслом. Выпекай хачапури в&nbsp;разогретой до&nbsp;200 градусов духовке 10&nbsp;минут. Затем уменьши температуру до&nbsp;160 градусов и&nbsp;продолжай выпекать изделия еще 15&nbsp;минут. В&nbsp;конце смажь поверхность топленым сливочным маслом.',
    sku: 'Слоеное Бездрожжевое Тесто',
    time: 90,
  },
  {
    id: 7,
    name: 'Ассорти пицца',
    picture: '7.jpg',
    ingredients: [
      'Ассорти пицца «Рестория»',
      '',
      'Майонез с перепелиными яйцами «Рестория»\n',
    ],
    process: 'На&nbsp;сухую керамическую или стеклянную посуду положи бумажное полотенце, сложенное в&nbsp;два раза. Помести замороженную пиццу поверх. Отправь разогреваться в&nbsp;микроволновку на&nbsp;3&nbsp;минуты при мощности 700&nbsp;Вт. Далее включи максимальную мощность и&nbsp;грей еще 5&ndash;7&nbsp;минут. Подавать можно с&nbsp;майонезом и&nbsp;есть, пока горячая!',
    sku: 'Ассорти пицца',
    time: 10,
  },
  {
    id: 1,
    name: 'Жареные пельмени со&nbsp;сметаной и&nbsp;сыром',
    picture: '1.jpg',
    ingredients: [
      '800 г классических пельменей «Рестория»',
      '100 г сыра',
      'Черный молотый перец «Рестория»',
      '50 г сливочного масла',
      '3 ст. л. сметаны',
      '1 стакан воды',
      'Зелень по вкусу',
    ],
    process: 'На&nbsp;разогретой сковороде растопи сливочное масло, добавь замороженные пельмени и&nbsp;обжаривай 5&ndash;7 минут на&nbsp;сильном огне. Потом добавь сметану и&nbsp;воду. Туши на&nbsp;медленном огне 5&nbsp;минут. Добавь тертый сыр, черный перец, накрой крышкой и&nbsp;оставь еще на&nbsp;пару минут. Готовое блюдо можно украсить измельченной зеленью.',
    sku: 'Классические Пельмени',
    time: 20,
  },
];
