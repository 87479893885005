(function () {
  setTimeout(() => {
    window.pageOffset = 0;
    const app = document.documentElement;

    window.disableScroll = () => {
      const offset = window.pageYOffset;
      document.documentElement.style.overflow = 'hidden';
      window.pageOffset = offset;
      app.style.cssText = `position: fixed; left: 0; right: 0;top: -${offset}px`;

      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (window.innerWidth > 1024 && !isSafari) {
        document.body.style.paddingRight = '17px';
      }
    };

    window.enableScroll = () => {
      document.documentElement.style.overflow = 'initial';
      app.style.cssText = '';
      window.scrollTo(0, window.pageOffset);
      if (window.innerWidth > 1024) {
        document.body.style.paddingRight = '0';
      }
    };
  });
}());
