export default [
  {
    name: 'Кордон блю',
    subtitle: 'с сыром и ветчиной',
    picture: 'pr1.png',
    bgColor: '#85C9AA',
    bgInColor: '#DCECE2',
    subtitleColor: '#EF8123',
    boxShadow: '0px 0px 25px #B5C2BA',
    category: [1, 3],
    recipeId: 4,
    testName: 'Кордон Блю',
  },
  {
    name: 'Пицца',
    subtitle: 'ассорти',
    picture: 'pr2.png',
    bgColor: '#DBB598',
    bgInColor: '#F5D8C3',
    subtitleColor: '#77360C',
    boxShadow: '0px 0px 25px #FFD8BA',
    category: [3],
    recipeId: 7,
    testName: 'Ассорти Пицца',
    style: {
      width: '57vw',
      margin: '0em 0 0 -7vw',
    },
  },
  {
    name: 'Фарш',
    subtitle: 'Говяжий',
    picture: 'pr3.png',
    bgColor: '#D1D1D1',
    bgInColor: '#EBEBEB',
    subtitleColor: '#A90222',
    boxShadow: '0px 0px 25px #E7E7E7',
    category: [2],
    recipeId: 2,
    testName: 'Говяжий Фарш',
    style: {
      margin: '10em 0px -4em -5em',
      width: '71em',
      height: '59em',
    },
  },
  {
    name: 'Купаты',
    subtitle: 'Фирменные',
    picture: 'pr4.png',
    bgColor: '#EDC6D0',
    bgInColor: '#FFFBFC',
    subtitleColor: '#E7899C',
    boxShadow: '0px 0px 25px #E8D0D6',
    category: [1],
    recipeId: 3,
    testName: 'Фирменные Купаты',
    style: {
      margin: '3em 0 0 -6vw',
    },
  },
  {
    name: 'Пельмени',
    subtitle: 'Классические',
    picture: 'pr5.png',
    bgColor: '#F8B5B5',
    bgInColor: '#FFEDED',
    subtitleColor: '#B31A39',
    boxShadow: '0px 0px 25px #F5D7D7',
    category: [1, 2, 3],
    recipeId: 1,
    testName: 'Классические Пельмени',
    style: {
      width: '47vw',
      margin: '0px 0px 0px -4vw',
      transform: 'rotate(39deg)',
    },
    sliderStyle: {
    },
  },
  {
    name: 'Азу из индейки',
    subtitle: 'Охлажденное',
    picture: 'pr6.png',
    bgColor: '#DEC0E2',
    bgInColor: '#F5EAF6',
    subtitleColor: '#8C4896',
    boxShadow: '0px 0px 25px #CCBCCD',
    category: [2],
    recipeId: 5,
    testName: 'Азу из Индейки',
    style: {
      margin: '2em 0px 0px 1vw',
      width: '50vw',
      transform: 'rotate(-74deg)',
    },
  },
  {
    name: 'Майонез',
    subtitle: 'С перепелиными яйцами',
    picture: 'pr7.png',
    bgColor: '#E9D098',
    bgInColor: '#FFEDCD',
    subtitleColor: '#806529',
    boxShadow: '0px 0px 25px #D7C3A0',
    testName: 'Майонез с Перепелиными яйцами',
    style: {
      width: '32vw',
      margin: '2em 0 0 -2vw',
    },
  },
  {
    name: 'Кетчуп',
    subtitle: 'Томатный',
    picture: 'pr8.png',
    bgColor: '#DE796F',
    bgInColor: '#FFE6E4',
    subtitleColor: '#BB1011',
    boxShadow: '0px 0px 25px #CC9C98',
    testName: 'Томатный Кетчуп',
    style: {
      width: '32vw',
      margin: '2em 0 0 -2vw',
    },
  },
  {
    name: 'Перец',
    subtitle: 'черный молотый',
    picture: 'pr10.png',
    bgColor: '#DE796F',
    bgInColor: '#FFE6E4',
    subtitleColor: '#BB1011',
    boxShadow: '0px 0px 25px #CC9C98',
  },
  {
    name: 'Тесто слоёное',
    subtitle: 'Бездрожжевое',
    picture: 'pr9.png',
    bgColor: '#88D190',
    bgInColor: '#F3FFF5',
    subtitleColor: '#31AC4D',
    boxShadow: '0px 0px 25px #A6D6AE',
    category: [2],
    recipeId: 6,
    testName: 'Слоеное Бездрожжевое Тесто',
    style: {
      width: '60vw',
      margin: '0 0 0 -9vw',
    },
  },
];
